/* src/components/banner-component.js */

import React from 'react'

const BannerComponent = (data) => {
    const { item, sectionKey, itemNdx } = data;
    return (
        <section className="banner">
            <img src={data.imgSrc} alt={data.alt} />
            <div className="strapline">Delicious Italian Cuisine - Pizzas - Burgers - Doners - Pastas</div>
        </section>
    );
};

export default BannerComponent