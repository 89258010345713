/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listMenuSectionsByOrder } from './graphql/queries'
import MenuSectionComponent from './components/menu-section-component'
import BannerComponent from './components/banner-component'
import ImageSectionComponent from './components/image-section-component'

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const App = () => {
  const [menuSections, setMenuSections] = useState([])

  useEffect(() => {
    fetchMenuSections()
  }, [])

  async function fetchMenuSections() {
    try {
      const menuSectionData = await API.graphql(graphqlOperation(listMenuSectionsByOrder, { type:'menuSection'}));
      const menuSections = menuSectionData.data.listMenuSectionsByOrder.items
      setMenuSections(menuSections)
    } catch (err) { console.log('error fetching menuSections') }
  }

  return (
    <div id="main">
      <BannerComponent imgSrc="/images/Barbe-Q.png" alt="Barge-Q Easingwold logo" />
      <ImageSectionComponent imgSrc="/images/team.jpg" alt="Barbe-Q team picture" />
      <section id="content" className="wrapper menu">
        {
          menuSections.map((menuSection, index) => MenuSectionComponent(menuSection, index))
        }
      </section>
    </div>
  )
}

const styles = {
  container: { margin: '0 auto', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', padding: 20 }
}

export default App