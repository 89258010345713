/* src/components/menu-item-component.js */

import React from 'react'

const MenuItemComponent = (data) => {
    const { item, sectionKey, itemNdx } = data;
    return (
        <tr className={item.isNew ? 'new' : null}>
            <td><span className="item-name">{item.name}</span> {item.description}</td>
            {item.prices.map((price, priceIndex) => (
                <td className="price" key={sectionKey + '-pc-' + itemNdx + '-item-' + priceIndex}>{price}</td>
            ))}
        </tr>
    )
};

export default MenuItemComponent