/* src/components/menu-section-component.js */

import React from 'react';
import MenuItemComponent from './menu-item-component';

const MenuSectionComponent = (menuSection, index) => {
    const sectionKey = menuSection.id ? menuSection.id : index
    return (
        <div className="table-wrapper" key={sectionKey}>
            <h2 className="menu-section-title">{menuSection.name}</h2>
            <table className={menuSection.cssClass} >
                <thead>
                    <tr>
                        <th key={sectionKey + '-descCol'}>{menuSection.description}</th>
                        {menuSection.priceColumnLabels.map((priceColumn, ndx) => (
                            <th className="price-column" key={sectionKey + '-pc-' + ndx}>{priceColumn}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {menuSection.items.map((item, itemNdx) => (
                        <MenuItemComponent item={item} sectionKey={sectionKey} itemNdx={itemNdx} key={sectionKey + '-' + itemNdx}/>
                    ))}
                </tbody>
            </table>
        </div>
    )
};

/*

                        <tr key={sectionKey + '-' + itemNdx}>
                            <td><span className="item-name">{item.name}</span> {item.description}</td>
                            {item.prices.map((price, priceIndex) => (
                                <td className="price" key={sectionKey + '-pc-' + itemNdx + '-item-' + priceIndex}>{price}</td>
                            ))}
                        </tr>



                    {menuSection.items.map((item, itemNdx) => (
                        <MenuItemComponent item={item} sectionKey={sectionKey} />
                    ))}

*/

export default MenuSectionComponent