/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMenuSection = /* GraphQL */ `
  query GetMenuSection($id: ID!) {
    getMenuSection(id: $id) {
      id
      type
      name
      order
      description
      priceColumnLabels
      cssClass
      items {
        name
        description
        prices
        isNew
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMenuSections = /* GraphQL */ `
  query ListMenuSections(
    $filter: ModelMenuSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        order
        description
        priceColumnLabels
        cssClass
        items {
          name
          description
          prices
          isNew
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMenuSectionsByOrder = /* GraphQL */ `
  query ListMenuSectionsByOrder(
    $type: String
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuSectionsByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        description
        priceColumnLabels
        cssClass
        items {
          name
          description
          prices
          isNew
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
