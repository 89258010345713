/* src/components/image-section-component.js */

import React from 'react'

const ImageSectionComponent = (data) => {
    const { item, sectionKey, itemNdx } = data;
    return (
        <section className="wrapper style1 spotlight alt">
            <div className="content">
                <div className="inner">
                    <h2>Express Order Hotline: <a href="tel:+441347822802" className="phone">(01347) 822802</a></h2>
                    <h3>Alternative Order line: 07551 885055</h3>
                    <h3 className="context">Market Place, Easingwold, <a className="postcode" href="https://www.google.co.uk/maps/place/Barbe-Q/@54.1218941,-1.1937591,17z/data=!3m1!4b1!4m5!3m4!1s0x487ecb074fd3a929:0x2c278185b1ce2c8!8m2!3d54.121891!4d-1.1915651">YO61 3AD</a></h3>
                    <ul>
                        <li><strong>ESTABLISHED SINCE 1988</strong></li>
                        <li>All food is freshly prepared on the premises</li>
                        <li>Always the BEST Pizzas &amp; Kebabs</li>
                        <li>Always the BEST Friendly Service</li>
                        <li>Always the BEST Value for Money</li>
                    </ul>
                </div>
            </div>
            <div className="image">
                <img src={data.imgSrc} alt={data.alt} data-position="30% 30%" />
            </div>
        </section>
    );
};

export default ImageSectionComponent